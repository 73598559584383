import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export const fadeUpTitle = () => {
  gsap.config({
    nullTargetWarn: false,
  });
  
  gsap.registerPlugin(ScrollTrigger);

  // 1文字ずつフェードインさせる親要素を取得
  const titleElements = document.querySelectorAll(".js-fadeUpTitle");

  titleElements.forEach((titleElement) => {
    // 1文字ずつフェードインさせる文字を囲む要素を取得
    const wrapperContents = titleElement.querySelectorAll('.js-fadeUpTitleWrapper');
    // 文字をspanタグで囲む
    wrapperContents.forEach((wrapperContent) => {
      const text = wrapperContent.innerHTML; // innerHTMLを使用して、<br>タグも含めて取得
      const textArray = text.split(/(<br\s*\/?>|&nbsp;)/gi); // <br>タグと&nbsp;を分割の基準に
      
      const spannedText = textArray.map((char) => {
      if (char.match(/<br\s*\/?>/gi) || char === '&nbsp;') { // <br>タグと&nbsp;はそのまま保持
        return char;
      } else {
        return char.split('').map((c) => {
        return `<span class="js-fadeUpTitleChar"><span>${c}</span></span>`;
        }).join('');
      }
      }).join('');
      
      wrapperContent.innerHTML = spannedText;
      

      gsap.to(wrapperContent.querySelectorAll(".js-fadeUpTitleChar"), {
        "--fadeTitle-position": "0",
        duration: 0.3,
        stagger: 0.1,
        scrollTrigger: {
          trigger: wrapperContent,
          start: "top 90%",
          end: "bottom 10%",
          toggleActions: "play none none none",
        },
        onComplete: () => {
          wrapperContent.style.setProperty("--bg-sizeX", "100%");
        },
      });
    });
  });
};