import Swiper from 'swiper/bundle';
import { Navigation, Autoplay } from 'swiper/modules';
import 'swiper/css/bundle';

export const flowSlider = () => {
  const flowSlider = document.querySelector('.js-flowSlider');

  const option = {
    direction: 'vertical',
    slidesPerView: 1,
    loop: true,
    speed: 800,
    modules: [Navigation, Autoplay],
    autoplay: {
      delay: 4000,
    },
    navigation: {
      nextEl: '.p-top--flow__navigation .swiper-button-next',
      prevEl: '.p-top--flow__navigation .swiper-button-prev',
    }
  }

  if (flowSlider) {
    new Swiper(flowSlider, option);
  }
};